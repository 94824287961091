// modules
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
// styles
import styles from './artist.module.scss'
// components
import SEO from '../components/shared/SEO/SEO'

// definitions
function ArtistPage({ data, location }) {
  const {
    file: {
      childMarkdownRemark: { frontmatter },
    },
  } = data

  const pageRoute = location.pathname
  const pageTitle = frontmatter.title
  const photo = frontmatter.photo.childImageSharp.fluid
  const bio = frontmatter.bio

  return (
    <>
      <SEO title={pageTitle} route={pageRoute} />
      <h1>{pageTitle}</h1>
      {photo ? <Img fluid={photo} className={styles.photo} /> : null}
      {bio ? <p>{bio}</p> : null}
    </>
  )
}

// exports
export default ArtistPage

// query
export const ArtistPageQuery = graphql`
  query {
    file(
      sourceInstanceName: { eq: "content" }
      relativePath: { eq: "pages/artist.md" }
    ) {
      childMarkdownRemark {
        frontmatter {
          title
          photo {
            childImageSharp {
              fluid(maxWidth: 175) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          bio
        }
      }
    }
  }
`
